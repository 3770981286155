<ng-container
    *ngIf="{
        isOpen: (appState.linksDialogState.isOpen | async) ?? false,
        externalLinks: (appState.linksDialogState.externalLinks | async) ?? [],
        dwDefinitions: appState.linksDialogState.dwDefinitions | async,
        linksTotalCount: appState.linksDialogState.linksTotalCount | async
    } as $"
>
    <interacta-dialog
        size="extra-large"
        [isOpen]="$.isOpen"
        (closing)="closeDialog()"
    >
        <h4 class="typo-t4" title>
            {{ 'DIGITAL_WORKPLACE.USEFUL_LINKS' | translate }}
        </h4>
        <div
            class="mt-20 flex h-screen flex-col gap-y-24 overflow-y-auto overflow-x-hidden"
            content
        >
            <interacta-input-text-v2
                *ngIf="($.linksTotalCount ?? 0) > 20"
                class="w-1/2"
                [control]="search"
                [icon]="'search-left'"
                [iconClickable]="false"
                [iconPosition]="'right'"
            ></interacta-input-text-v2>
            <interacta-external-links
                *ngIf="$.externalLinks.length"
                [externalLinks]="$.externalLinks"
            ></interacta-external-links>
            <div *ngIf="$.dwDefinitions?.length" class="flex flex-col gap-y-44">
                <interacta-accordion
                    *ngFor="let digitalWorkplaceDefinition of $.dwDefinitions"
                    [isOpen]="
                        showusefulLinks[digitalWorkplaceDefinition.id] ?? true
                    "
                    [label]="digitalWorkplaceDefinition.name | currentLanguage"
                    [size]="'extra-large'"
                    (toggleOpen)="
                        toggleAccordion(digitalWorkplaceDefinition.id)
                    "
                >
                    <div
                        class="bg-surface-B flex flex-col gap-y-24 rounded-2xl p-16"
                    >
                        <div
                            *ngFor="
                                let widget of digitalWorkplaceDefinition.widgets
                            "
                            class="flex flex-col gap-y-24"
                        >
                            <div
                                *ngIf="widget.tag === WidgetType.USEFUL_LINK"
                                class="flex flex-col gap-y-24"
                            >
                                <h5 class="typo-t5">{{ widget.name }}</h5>

                                <div class="flex flex-wrap gap-x-32 gap-y-40">
                                    <interacta-useful-link
                                        *ngFor="
                                            let group of widget.linksGroups;
                                            let idx = index
                                        "
                                        [idx]="idx"
                                        [links]="group.links"
                                        [name]="group.name | currentLanguage"
                                        (imageLoadError)="
                                            temporaryLinkErrorHandlerService.handleUsefulLinkError(
                                                $event
                                            )
                                        "
                                    ></interacta-useful-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </interacta-accordion>
            </div>

            <div
                *ngIf="!$.externalLinks?.length && !$.dwDefinitions?.length"
                class="flex grow items-center justify-center"
            >
                <interacta-no-result
                    imageName="no-results"
                    [subtitle]="'DIGITAL_WORKPLACE.CHANGE_SEARCH' | translate"
                    [title]="'DIGITAL_WORKPLACE.NO_LINK' | translate"
                ></interacta-no-result>
            </div>
        </div>
    </interacta-dialog>
</ng-container>
