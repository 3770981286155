/**
 * Least Recently Used Cache with max size.
 * When a new entry is added and max size is reached,
 * the least recently used entry is removed to recover space.
 */
export class LruCache<T> {
    private values: Map<string, T> = new Map<string, T>();
    private maxEntries: number = 100;

    constructor(maxEntries?: number) {
        if (maxEntries != null) {
            this.maxEntries = maxEntries;
        }
    }

    public get(key: string): T | undefined {
        const entry = this.values.get(key);
        if (entry !== undefined) {
            // peek the entry, re-insert for LRU strategy
            this.values.delete(key);
            this.values.set(key, entry);
        }

        return entry;
    }

    public put(key: string, value: T) {
        if (this.values.size >= this.maxEntries) {
            // least-recently used cache eviction strategy
            const keyToDelete = this.values.keys().next().value;

            this.values.delete(keyToDelete);
        }

        this.values.set(key, value);
    }
}
