import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    AccordionComponent,
    ApplyPipe,
    DialogComponent,
    ImageComponent,
    InputTextV2Component,
} from '@interacta-shared/ui';
import { NoResultComponent } from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { LinksDialogComponent } from './components/links-dialog/links-dialog.component';
import { UsefulLinkComponent } from './components/useful-link/useful-link.component';

const components = [UsefulLinkComponent, LinksDialogComponent];

const standaloneComponents = [
    IconComponent,
    AccordionComponent,
    ImageComponent,
    NoResultComponent,
    DialogComponent,
    InputTextV2Component,
];

const standalonePipes = [ApplyPipe];
@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        SharedV2Module,
        ...standaloneComponents,
        ...standalonePipes,
    ],
    exports: components,
})
export class UsefulLinkModule {}
