<div class="w-204 h-full">
    <ng-container *ngIf="links.length === 1; else moreThanOne">
        <button
            *ngIf="links[0] as link"
            class="bg-surface-A h-fit w-full rounded-2xl p-8 text-left shadow"
            type="button"
            [attr.aria-disabled]="isHomePreview"
            [disabled]="isHomePreview"
            (click)="navigateTo(links[0])"
        >
            <div
                class="bg-surface-A flex aspect-video justify-center overflow-hidden rounded-lg"
            >
                <interacta-image
                    *ngIf="
                        link.temporaryContentPreviewImageLink ??
                            link.temporaryContentViewLink as src;
                        else noImage
                    "
                    classes="h-full w-auto"
                    alt="{{ link.name }}'s image"
                    [src]="src"
                    (loadError)="imageLoadError.emit(src.split('?')[0])"
                ></interacta-image>
                <ng-template #noImage>
                    <div
                        *ngIf="!link.communityId; else communityDefaultCover"
                        class="h-full w-full"
                        [ngStyle]="{
                            background:
                                idx | apply: getRandomSecondaryColorVariables
                        }"
                    ></div>
                </ng-template>
                <ng-template #communityDefaultCover>
                    <interacta-image
                        classes="h-full w-auto"
                        [alt]="'Community cover fallback'"
                        [src]="'assets/images-2.0/fallback.jpg'"
                    ></interacta-image>
                </ng-template>
            </div>
            <div class="mt-8 flex h-24 items-center justify-between">
                @if (link.name | currentLanguage; as name) {
                    <p
                        class="typo-sm line-clamp-1 break-words"
                        [matTooltip]="name"
                    >
                        {{ name }}
                    </p>
                }

                <interacta-icon
                    *ngIf="!link.communityId"
                    class="h-24 w-24"
                    [icon]="'external-link'"
                ></interacta-icon>
            </div>
        </button>
    </ng-container>
    <ng-template #moreThanOne>
        <div class="flex h-full w-full flex-col gap-y-8">
            <p
                *ngIf="name"
                class="typo-sm-bold-uppercase line-clamp-1 break-words"
            >
                {{ name }}
            </p>
            <button
                *ngFor="let link of links"
                class="bg-surface-A flex h-24 w-full flex-1 items-center justify-between rounded-lg px-8 py-4 text-left shadow"
                type="button"
                [attr.aria-disabled]="isHomePreview"
                [disabled]="isHomePreview"
                (click)="navigateTo(link)"
            >
                @if (link.name | currentLanguage; as name) {
                    <p
                        class="typo-sm line-clamp-1 break-words"
                        [matTooltip]="name"
                    >
                        {{ name }}
                    </p>
                }
                <div class="h-24 w-24">
                    <interacta-icon
                        *ngIf="!link.communityId"
                        [icon]="'external-link'"
                    ></interacta-icon>
                </div>
            </button>
        </div>
    </ng-template>
</div>
