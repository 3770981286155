import { Injectable, inject } from '@angular/core';
import { LruCache } from '@interacta-shared/util';
import { Store } from '@ngrx/store';
import { differenceInHours } from 'date-fns';
import { fetchHomeDefinitions } from '../store/home-pages/home-pages.actions';

interface LoadError {
    lastError: Date;
}

@Injectable({
    providedIn: 'root',
})
export class TemporaryLinkErrorHandlerService {
    private handledErrors = new LruCache<LoadError>();

    private store = inject(Store);

    handleUsefulLinkError(src: string): void {
        const alreadyHandled = this.handledErrors.get(src);
        const lastError = new Date();
        if (
            alreadyHandled == null ||
            differenceInHours(alreadyHandled.lastError, lastError) > 0
        ) {
            this.store.dispatch(fetchHomeDefinitions({ force: true }));

            this.handledErrors.put(src, { lastError });
        }
    }
}
