import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { getRandomSecondaryColorVariables } from '@interacta-shared/util';
import { dashboardCommunity } from '@modules/app-routing/routes';
import { UsefulLink } from '@modules/useful-link/models/useful-link.model';

@Component({
    selector: 'interacta-useful-link',
    templateUrl: './useful-link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsefulLinkComponent {
    @Input({ required: true }) links!: UsefulLink[];
    @Input({ required: true }) idx!: number;
    @Input() name = '';
    @Input() isHomePreview = false;

    @Output() imageLoadError = new EventEmitter<string>();

    private PROTOCOL_VALIDATOR = new RegExp(/^[^:]+(?=:\/\/)/);

    constructor(private router: Router) {}

    getRandomSecondaryColorVariables = getRandomSecondaryColorVariables;

    navigateTo(link: UsefulLink): void {
        if (link.communityId) {
            this.router.navigate([dashboardCommunity, link.communityId]);
        } else if (link.url) {
            window.open(
                this.PROTOCOL_VALIDATOR.exec(link.url)
                    ? link.url
                    : `https://${link.url}`,
                '_blank',
            );
        }
    }
}
